<template>
  <div>
    <h2 v-html="$t('search_label_title')"></h2>
    <!-- Label -->
    <v-select
      id="search_label_label"
      v-model="searchCardStore.ProcessingForm_milkLabel"
      :label="$t('search_label_label')"
      :placeholder="$t('search_label_all')"
      :items="milkLabels"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'
import { useMilkLabelsStore } from '@/store/enums/MilkLabelsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchLabel',
  extends: baseSearchVue,
  data() {
    return {}
  },
  milkLabels() {
    return useMilkLabelsStore().milkLabels
  },

  beforeMount() {
    this.setSearchCardStore(useSearchProcessingFormStore(), true)
    useMilkLabelsStore().fetchMilkLabels()
  }
})
</script>
